<script setup lang="ts">
defineProps<{
  error?: string | undefined;
}>();

const model = defineModel<Date | null | undefined | string>('modelValue', {
  type: Date,
});

const dateString = defineModel('dateString', {
  type: String,
});

const timeString = defineModel('timeString', {
  type: String,
});

watch(
  () => model.value,
  (value, oldValue) => {
    if (!oldValue && !value) return;

    if (!value) {
      dateString.value = '';
      timeString.value = '';
      return;
    }

    dateString.value = formatDateToYYYYMMDD(new Date(value));
    timeString.value = formatDateToHi(new Date(value));
  },
  { immediate: true },
);

onMounted(() => {
  if (timeString.value) {
    model.value = setTodayWithTime(timeString.value);
  }

  if (dateString.value) {
    model.value = formatDateToYYYYMMDD(new Date(dateString.value));
  }

  if (model.value) {
    model.value = new Date(model.value);
  }
});
</script>

<template>
  <label
    class="block flex-1 text-left text-sm font-medium leading-3 text-gray-iron-700"
    :class="$attrs.class"
  >
    <slot></slot>

    <PrimeDatePicker
      v-bind="$attrs"
      v-model="model"
      dateFormat="dd/mm/yy"
      class="mt-1.5 block w-full"
      :invalid="!!error"
      showIcon
      fluid
      iconDisplay="input"
      :inputClass="['text-sm !w-full', { 'text-center': 'timeOnly' in $attrs }]"
    >
      <template v-if="'timeOnly' in $attrs" #inputicon="slotProps">
        <i class="pi pi-clock" @click="slotProps.clickCallback" />
      </template>
    </PrimeDatePicker>

    <small class="mt-1 text-left text-xs text-red-600">
      {{ error }}
    </small>
  </label>
</template>
